import EmploymentItem from "./EmploymentItem"
import './employment.css'
import employmentList from "./employmentsList"

export default function Employments() {
    const list = employmentList.map((employment, index)=>{
        return <EmploymentItem key={index} employment={employment}/>
    })
    return (
        <div className="projects-container" id="projects-container">
            <h4>Work and Projects</h4>
            <h2>Employment</h2>
            <div>
                {list}
            </div>
        </div>
    )
}