import useWindowDimensions from "../../hooks/useWindowDimensions"

const EmploymentItem = ({ employment }) => {
    const { width } = useWindowDimensions()

    const responsibilities = employment.responsibilities.map((res, idx) => {
        return (
            <li key={idx}>• {res.task}</li>
        )
    })

    const skills = employment.skill.map((skill, index)=>{
        return(
            <li key={index}>{skill.name}</li>
        )
    })

    return (
        <div className="employment-details">
            <div className="employment-data-container">
                <b>{employment.name}</b>
                { width > 800
                    ? <p>{employment.date}</p>
                    :undefined
                }
            </div>
            <div className="employment-data-container">
                <p>{employment.company}</p>
                { width > 800
                    ? <p>{employment.location}</p>
                    :undefined
                }
            </div>
            <ul className="responsiblities">{responsibilities}</ul>
        </div>
    )
}

export default EmploymentItem