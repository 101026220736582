const skillsList = [
    {
        type: "Language",
        skill: [
           {
                name: "JavaScript"
            },{
                name: "Java"
            },{
                name: "C / C++"
            },{
                name: "SQL / NoSQL"
            },{
                name: "Python*"
            }
        ]
    },{
        type: "Web Dev",
        skill: [
            {
                name: "React"
            },{
                name: "Node.js"
            },{
                name: "Express.js"
            },{
                name: "HTML + CSS"
            },{
                name: "Redux"
            },{
                name: "Angular*"
            }
        ]
    },{
        type: "Database",
        skill: [
            {
                name: "Oracle Database"
            },{
                name: "PostgreSQL"
            },{
                name: "MongoDB"
            }
        ]
    },{
        type: "Other",
        skill: [
            {
                name: "Git"
            },{
                name: "Azure Devops"
            },{
                name: "Jira"
            },{
                name: "Confluence"
            },{
                name: "Unit Testing"
            }
        ]
    }
]

export default skillsList;