const employmentList = [
    {
        name: "Application Developer (Co-op)",
        responsibilities: [
            {
                task: "Integrated automated unit testing in CI/CD pipelines"
            },{
                task: "Wrote Shell script to cancel running CI build jobs if a new build of the same service gets triggered, reducing time and resource consumption by at least 40%"
            },{
                task: "Automated triggering of build and deployment pipelines with changes to Git repository"
            },{
                task: "Documented microservices, defining endpoints, usage, and container resources requests and limits"
            },{
                task: "Created unit tests for Spring Boot microservices using JUnit, with at least 95% coverage"
            },{
                task: "Worked in an Agile environment and attended daily SCRUM meetings"
            }
        ],
        company: "CIBC Capital Markets",
        date: "Jan 2022 - Apr 2022",
        location: "Toronto, ON",
        source: "https://cibccm.com/en/",
        skill: [{
            name: "Java"
        },{
            name: "Azure DevOps"
        },{
            name: "Spring Boot"
        },{
            name: "Kubernetes"
        },{
            name: "OpenShift"
        },{
            name: "Jira"
        },{
            name: "Confluence"
        },{
            name: "Git"
        }]
    }
]

export default employmentList
