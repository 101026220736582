const projectList = [
    {
        name: "DevSpot - WIP",
        description : "Blogging platform for tech related topics. Users can browse for posts of various topics, follow their favorite authors for future updates, and even publish posts of their own!",
        image: "/images/devSpot.png",
        source: "https://github.com/jianylei/DevSpot",
        color: "black",
        skill: [{
            name: "JavaScript"
        },{
            name: "React"
        },{
            name: "Node.js"
        },{
            name: "Express.js"
        },{
            name: "MongoDB"
        },{
            name: "Redux"
        },{
            name: "Jest"
        }]
    },{
        name: "shopNotes",
        description : `Note tracking web application created to replace company’s existing sticky board note system - 
        improving employee efficiency and workflow. Main features include ability to create notes/task and 
        assign them to specific employees, and marking task as "completed" once finished.`,
        image: "/images/shopNotes.png",
        live: "https://jyl-notes.onrender.com/",
        source: "https://github.com/jianylei/shopNotes",
        color: "black",
        skill: [{
            name: "JavaScript"
        },{
            name: "React"
        },{
            name: "Node.js"
        },{
            name: "Express.js"
        },{
            name: "MongoDB"
        },{
            name: "Redux"
        }]
    },{
        name: "myMusicApp",
        description : `Application developed to browse and discover new songs - using Spotify's API for data. 
        Users are able to view and listen to snippets of the latest releases, along with 
        the ability to add songs to their favourites lists. Back end features a Node with Express server, using MongoDB
         for user data. Front-end built with Angular + Material UI.`,
        image: "/images/myMusicApp1.png",
        live: "https://music-app-422.netlify.app",
        source: "https://github.com/jianylei/myMusicApp",
        color: "white",
        skill: [{
            name: "MongoDB"
        },{
            name: "ExpressJs"
        },{
            name: "Angular"
        },{
            name: "NodeJs"
        },{
            name: "Material UI"
        }]
    },{
        name: "HR Portal",
        description : `HR Portal is a CRUD app designed to help businesses manage their employees. The
            back-end consist of a Nodejs server using Expressjs to render handlebar views. The server also handles authentication, authorization, and validation.`,
        image: "/images/hr1.png",
        live: "https://hr-portal-myapp.herokuapp.com/",
        source: "https://github.com/jianylei/hr-portal",
        color: "white",
        skill: [{
            name: "JavaScript"
        },{
            name: "NodeJs"
        },{
            name: "ExpressJs"
        },{
            name: "PostgreSQL"
        },{
            name: "MongoDB"
        },{
            name: "Bootstrap"
        }]
    },
    {
        name: "myLibrary",
        description : `Book tracking application aimed to help users track their reading progress.
            As it stands, MyLibrary has full CRUD functionalities integrated with localStorage along with a
            responsive UI for both desktops and mobile.`,
        image: "/images/mylibrary1.png",
        live: "https://jianylei.github.io/myLibrary/",
        source: "https://github.com/jianylei/myLibrary",
        color: "white",
        skill: [{
            name: "JavaScript"
        },{
            name: "HTML"
        },{
            name: "CSS"
        },{
            name: "Bootstrap"
        }]
    },{
        name: "Assembly Line",
        description : `Final term project putting C++ Object Oriented skills to practice by developing a simulation of an assembly line 
        with any number of stations. 
        Project completed using various OOP concepts such as inheritance, encapsulation, polymorphism, data abstraction, 
        along with STL contaioners and algorithms. Achieved grade of A+`,
        image: "/images/oop1.png",
        source: "https://github.com/jianylei/assembly-line-oop345",
        color: "white",
        skill: [{
            name: "C++"
        }]
    }
]

export default projectList
